import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Icon, Pagination, Popup, Input, Image, TextArea, Form } from 'semantic-ui-react';
import styles from './PieceRate.module.css'
import ErrorMessage from '../../Commons/ErrorMessage';
import { authDelete, authPost } from "../../ajax";
import { isTaskAvailable } from '../../utils';
import MessageBoxOkOnly from "../../Commons/MessageBoxOkOnly";
import MessageBoxYesNo from "../../Commons/MessageBoxYesNo";

const pieceRateModel = {
    Id : "",
    Code : "",
    UnitOfMeasurement : "pcs",
    RatePerUnit : 0.00,
    Description : ""
}

const errorModel = {
    errTitle : "",
    errCaption : ""
}

const msgBoxModel = {
    Title : "",
    Message : ""
}

const searchModelParam = {
    Needle : "",
    Skip : 0,
    Take : 10,
}

export default function PieceRate(){
    //User Rights
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const [rateList, setRateList] = useState([]);
    const [prModel, setPrModel] = useState(pieceRateModel);

    //Delete Controls
    const [selectedId, setSelectedId] = useState();

    //Search Controls
    const [activePage, setIsActivePage] = useState(1);
    const [totalPages, setIsTotalPages] = useState(0);
    const [searchParam, setSearchParam] = useState(searchModelParam);

   //UI Controls
    const [isPopUp, setIsPopUp] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isMsgOk, setIsMsgOk] = useState(false);
    const [isMsgYesNo, setIsMsgYesNo] = useState(false);
    const [errModel, setErrModel] = useState(errorModel);
    const [msgBox, setMsgBox] = useState(msgBoxModel);

    //On change properties
    const togglePopUp = () => {
        setPrModel(pieceRateModel);
        setIsError(false);
        setIsPopUp(prevCheck => !prevCheck);
    }
    const handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setPrModel(prevState => ({
            ...prevState,
            [name] : value
        }));
    }
    const handleSearchChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearchParam(prevState =>({
            ...prevState,
            [name] : value
        }));
    }
    const onPageChange = (currentPage) =>{
        setIsActivePage(currentPage);
    }
    const onEdit = async(data) =>{
        setPrModel(prevState => ({
            ...prevState,
            Id : data.id,
            Code : data.code,
            UnitOfMeasurement : data.unitOfMeasurement,
            RatePerUnit : data.ratePerUnit,
            Description : data.description,
        }));
        setIsPopUp(true);
    }
    const onDelete = (data) =>{
        setSelectedId(data.id);
        setIsMsgYesNo(true);
    }
    useEffect(()=>{
        search();
    },[activePage])

    // User rights
    useEffect(()=>{
        const canAddValue = isTaskAvailable("PIECE_RATE_CREATE");
        const canEditValue = isTaskAvailable("PIECE_RATE_EDIT");
        const canDeleteValue = isTaskAvailable("PIECE_RATE_DELETE");

        setCanAdd(canAddValue);
        setCanEdit(canEditValue);
        setCanDelete(canDeleteValue);
    }, []);

    //API Controls
    async function search() {
            searchParam.Skip = (activePage - 1) * 10;
            const response = await authPost('/pieceratetype/search', searchParam);
            const result = await response.json();
            setRateList(result.data);
            setIsTotalPages(result.count);
        }
    const add = async () =>{
        if(prModel.Code === "" || prModel.UnitOfMeasurement === "" || prModel.RatePerUnit === 0){
            errModel.errCaption = "Please fill up all required field/s";
            errModel.errTitle = "Invalid";
            setIsError(true);
        }else if(prModel.Code.length > 50){
            errModel.errCaption = "Code is over 50 characters! Please input below 50 characters";
            errModel.errTitle = "Invalid";
            setIsError(true);
        }
        else{
            const response =await authPost('/pieceratetype/save', prModel);
            if(response.status == 200){
                msgBox.Title = "Add New Piece Rate";
                msgBox.Message = "Successfully Saved !";
                const result = await response.json();
                setRateList([...rateList, result]);
                setIsPopUp(false);
                setIsMsgOk(true);
            }
        }
    }
    const editRecord = async () =>{
        const response = await authPost('/pieceratetype/edit', prModel);
        if(response.status == 200){
            msgBox.Title = "Edit Piece Rate";
            msgBox.Message = "Successfully Edited !";
            setRateList(rateList.map(x=>x.id === prModel.Id ? {
                ...x,
                code : prModel.Code,
                unitOfMeasurement : prModel.UnitOfMeasurement,
                ratePerUnit : prModel.RatePerUnit,
                description : prModel.Description
            }: x));
            setIsPopUp(false);
            setIsMsgOk(true);
        }
    }
    const deleteRecord = async () =>{
        const response = await authDelete('/pieceratetype/delete', selectedId);
        if(response.status == 200){
            msgBox.Title = "Delete Piece Rate";
            msgBox.Message = "Successfully Deleted !";
            setRateList(rateList.filter(x=>x.id !== selectedId));
            setIsMsgYesNo(false);
            setIsMsgOk(true);
        }
    }

    return(


        <div className={styles.module}>
            <div className={styles.headerLabel}>
                <h2>Piece Rate</h2>
            </div>
            <div className={styles.controlContainer}>
                <div className={styles.leftHeader}>
                { canAdd &&
                    <Button size='mini' fluid={window.innerWidth < 768}  style={{ 'max-height': '31px' }} onClick={togglePopUp} >
                        <Icon name='plus' /> New
                    </Button>
                }

                </div>
                <div className={styles.rightHeader}>
                    <Input placeholder="Search Code" name="Needle" autoFocus required fluid size="mini" type='text' style={{ minWidth: '50px', width: '20%' }} value={searchParam.Needle}
                        onChange={handleSearchChange}/>
                    <Button size='mini' fluid={window.innerWidth < 768}  style={{ 'max-height': '31px' }} onClick={search} >
                            <Icon name='search' /> Search
                        </Button>
                </div>
            </div>
            <div className={styles.paginationContainer}>
                <Pagination
                    style={{alignSelf:"end"}}
                    defaultActivePage={1}
                    pointing
                    secondary
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={Math.ceil(totalPages / 10)}
                    activePage={activePage}
                    onPageChange={(e, { activePage }) => onPageChange(activePage)}
                />
            </div>
            <div className={styles.bodyWrapper}>
                <Table stackable selectable striped size='small'>
                    <Table.Header>
                        <Table.Row className={styles.tableDesign}>
                            <Table.HeaderCell>Code</Table.HeaderCell>
                            <Table.HeaderCell>Unit Of Measurement</Table.HeaderCell>
                            <Table.HeaderCell>Rate Per Unit</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { rateList.map((data, index) =>(
                            <Table.Row>
                                <Table.Cell>{data.code}</Table.Cell>
                                <Table.Cell>{data.unitOfMeasurement}</Table.Cell>
                                <Table.Cell>{data.ratePerUnit}</Table.Cell>
                                <Table.Cell>{data.description}</Table.Cell>
                                <Table.Cell>
                                    <div>
                                        {canEdit &&
                                            <Popup trigger={
                                            <Button icon='edit' circular onClick={()=> onEdit(data)}></Button>} content='Edit' inverted />
                                        }
                                        {canDelete &&
                                            <Popup trigger={
                                            <Button icon='delete' circular onClick={()=> onDelete(data)}></Button>} content='Delete' inverted />
                                        }
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        </Table.Body>
                </Table>
            </div>
            { isPopUp &&
                    <Modal open={true} size='mini'>
                        <Modal.Header>Piece Rate</Modal.Header>
                        <Modal.Content>
                        {
                            isError &&
                                <div className="ui one column grid" style={{"margin": "0px", "padding": "0px"}} >
                                    <div className="column" style={{"margin": "0px 0px 10px 0px", "padding": "0px"}}>
                                        <ErrorMessage
                                            title={errModel.errTitle}
                                            caption={errModel.errCaption}
                                            open={isError}
                                        />
                                    </div>
                                </div>
                        }
                            <div className={styles.popupContainer}>
                                <div className={styles.columns}>
                                    <span>Code</span>
                                    <div>
                                        <Input name="Code" autoFocus required fluid size="small" type='text' value={prModel.Code} onChange={handleChange}
                                            error={isError && !prModel.Code} className={styles.inputProperty} />
                                    </div>
                                </div>
                                <div className={styles.columns}>
                                    <span>Unit Of Measurement</span>
                                    <div>
                                        <Input name="UnitOfMeasurement" required fluid size="small" type='text' value={prModel.UnitOfMeasurement} onChange={handleChange}
                                            error={isError && !prModel.UnitOfMeasurement} className={styles.inputProperty}/>
                                    </div>
                                </div>
                                <div className={styles.columns}>
                                    <span>Rate Per Unit</span>
                                    <div>
                                        <Input name="RatePerUnit" required fluid size="small" type='number' value={prModel.RatePerUnit} onChange={handleChange}
                                            error={isError && !prModel.RatePerUnit} className={styles.inputProperty}/>
                                    </div>
                                </div>
                                <div className={styles.columns}>
                                    <span>Description</span>
                                    <div>
                                        <Form>
                                            <TextArea name="Description" rows={5} value={prModel.Description} onChange={handleChange} 
                                                error={isError && !prModel.Description} className={styles.textAreaStyle} />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button icon='save' content='Save' onClick={prModel.Id == "" ? add : editRecord} />
                            <Button content='Cancel' onClick={togglePopUp} />
                        </Modal.Actions>
                    </Modal>
            }

            { isMsgOk &&
                    <MessageBoxOkOnly title={msgBox.Title} action="Yes"
                        caption={msgBox.Message}
                        onClick={()=> setIsMsgOk(false)}
                    />
            }

            { isMsgYesNo &&
                <MessageBoxYesNo title="Delete Piece Rate" action="Yes" CancelCaption="No"
                    caption="Are you sure you want to delete this Record ?" 
                    onClose = { () => setIsMsgYesNo(false) }
                    onAction = {deleteRecord}/>
            }
       </div>
       )
}

PieceRate.getViewID = () => { return "PieceRate" };
PieceRate.getCaption = () => { return "Piece Rate Management" };
PieceRate.getViewAccessKey = () => {
    return isTaskAvailable("PIECE_RATE_VIEW");
}

PieceRate.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Fringe_Benefits.png' avatar />);
}
PieceRate.getIconSrc = (size) => {
    return '/Icons/Fringe_Benefits.png';
}

