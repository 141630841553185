import React, { Component } from 'react';
import { Button, Image, Menu, Radio, Table, Pagination, Icon } from 'semantic-ui-react';
import Select from 'react-select';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import EmployeeFilterModal from '../../Commons/EmployeeFilterModal';

import modStyles from './RemoteClockAssignmentList.module.css';

var { fnSearchEmployee, loadGroups, fnSearchEmployeeFilter, empty_id, checkDate, fullName, ajaxPostDownload, isTaskAvailable } = require('../../utils');

export const customStyles =
{
    control: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    valueContainer: (base) => ({
        ...base,
        minHeight: 36,
        maxHeight: 95,
        width: 300,
        overflowY: 'auto'
    }),
    menu: (base) => ({
        ...base,
        zIndex: 1000,
    })
};

function empSearchList(empList) {
    var list = [];
    if (empList !== undefined) {
        empList.map(x => {
            var fullname = x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]);

            if (fullname.length > 45)
                fullname = fullname.slice(0, 45) + "..." + " (" + x.EmployeeNo + ")";
            else
                fullname = fullname + " (" + x.EmployeeNo + ")";

            return (
                list.push({ label: fullname, value: x._id }))
        })
    }
    return list;
}


class RemoteClockAssignmentList extends Component {
    constructor() {
        super();
        this.state = {
            isFirstLoad: true,
            selected: [],
            isFilterActive: false,
            includeInactive: false,
            list: [],
            count: 0,
            startIndex: 0,
            pageNumber: 0,
            activePage: 1,
            rowsPerPage: 20,
            empSearchList: [],
            showOkDialog: false,
            hasAdvanceFilter: false,
            clockingAll: false,
            filingAll: false,
            payrollTransactionAll: false,
            dtrAll: false,
            advanceFilter: {
                employeeIds: [],
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
        }
    }

    styles =
        {
            toolbar: {
                display: 'flex',
                justifyContent: 'flex-end'
                // width: '50%'
            },
            searchBar: {
                display: 'flex',
            },
            table: {
                // width: '50%'
            }
        }

    onViewFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }

    onSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ search: text });
        this.searchTimer = setTimeout(this.loadSearchEmployee, 400, text);
    }


    loadSearchEmployee = (str) => {
        const { empSearchList, selected, hasAdvanceFilter } = this.state;

        this.setState({ isSearchLoading: true });
        var ids = (selected === "" || selected === undefined || selected.length == 0) ? [] : selected.map(x => x.value);
        var selectedEmpIds = empSearchList === undefined || empSearchList === null ? [] : empSearchList.filter(x => ids.includes(x._id));
        if (hasAdvanceFilter)
            this.onAdvanceEmployeeSearchFilter(0, selectedEmpIds.map(x => x._id), this.actualSearchEmployee.bind(this))
        else
            this.actualSearchEmployee(str, 0, selectedEmpIds.map(x => x._id), null);

    }

    actualSearchEmployee(str, startIndex, excludedIds, includedEmployee) {
        var { empSearchList } = this.state;
        var excludedEmployee = [];
        if (excludedIds != null)
            excludedEmployee = empSearchList.filter(x => excludedIds.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            if (excludedIds != null)
                excludedEmployee.forEach(x => { if (!data.map(y => y._id).includes(x._id)) data.push(x) })

            this.setState({
                minIndex: 0,
                maxIndex: 0,
                isSearchLoading: false,
                empSearchList: data,
                search: "",
            })
        }, startIndex, 20, this.state.includeInactive, excludedIds, includedEmployee != null ? includedEmployee.map(x => x._id) : null)
    }

    onAdvanceEmployeeSearchFilter(startIndex, excludedIds, callback) {
        var { advanceFilter } = this.state;
        var totalCount = 0;
        var employees = [];
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeInactive,
                "startingIndex": startIndex,
                "itemCount": 12,
                "excludedEmployeeIds": excludedIds,
                "CompanyIDs": advanceFilter.companyIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
            },
            onSuccess: data => {
                totalCount = data.count;
                employees = data.content;

                if (employees.length === 0)
                    employees.push({ "_id": empty_id });
            },
            finally: () => {
                callback("", 0, excludedIds, employees);
            }
        })
    }

    onEmployeeSelect = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        var { hasAdvanceFilter } = this.state;
        this.setState({ selected: value })

        if (hasAdvanceFilter)
            this.onAdvanceEmployeeSearchFilter(0, ids, this.actualSearchEmployee.bind(this))
        else
            this.actualSearchEmployee("", 0, ids, null);
    }

    loadSearchEmployee = (str) => {
        const { empSearchList, selected, hasAdvanceFilter } = this.state;

        this.setState({ isSearchLoading: true });
        var ids = (selected === "" || selected === undefined || selected.length == 0) ? [] : selected.map(x => x.value);
        var selectedEmpIds = empSearchList === undefined || empSearchList === null ? [] : empSearchList.filter(x => ids.includes(x._id));
        if (hasAdvanceFilter)
            this.onAdvanceEmployeeSearchFilter(0, selectedEmpIds.map(x => x._id), this.actualSearchEmployee.bind(this))
        else
            this.actualSearchEmployee(str, 0, selectedEmpIds.map(x => x._id), null);

    }

    componentDidMount = () => {

        if (this.state.isFirstLoad) {
            const testList = [];
            testList.push({ Employee: "Sample" });
            this.setState({ list: testList, isFirstLoad: false });
            this.loadSearchEmployee("");
            this.doSearch("", this.state.activePage);
        }
    }

    onClickSearch = (event) => {
        this.doSearch(this.state.search);
    }

    handlePaginationChange = (e, { activePage }) => {

        if (isNaN(activePage) || activePage == 0) {
            activePage = 1;
        }

        this.setState({ activePage, loading: true }, this.doSearch("", activePage));
    }

    doSearch = (str, activePage) => {
        this.setState({ loading: true });
        const ids = [];
        const startIndex = (activePage - 1) * this.state.rowsPerPage;

        this.state.selected.map(x => {
            ids.push(x.value);
        });

        ajaxPost({
            url: "api/RemoteClockAssignment/search",
            data: {
                "search": str,
                "employeeIds": ids,
                "startingIndex": startIndex,
                "itemCount": this.state.rowsPerPage,
                "AdvanceFilter": JSON.stringify(this.state.advanceFilter)
            },
            onSuccess: data => {
                this.setState({
                    list: data.content,
                    count: data.content.length,
                });
                var res = Math.ceil(data.count / this.state.rowsPerPage);
                var count = parseInt(res);
                this.setState({ startIndex: startIndex, pageNumber: count });
            },
            finally: () => {
                this.setState({ loading: false });
            }
        })

    }

    onClickSearch = (event) => {
        if (this.state.selected !== undefined && this.state.selected.length === 1 && this.state.selected[0] === empty_id)
            this.setState({ selected: undefined, minIndex: 0, maxIndex: 0, employeeList: [] }, () => this.doSearch("", this.state.activePage))
        else
            this.setState({ minIndex: 0, maxIndex: 0, employeeList: [] }, () => this.doSearch("", this.state.activePage));
    }

    onToggleChange = (id, field) => {
        const { list } = this.state; 
        var value = !list.find(x => x.EmployeeId == id)[field];
        list.find(x => x.EmployeeId == id)[field] = value;

        var fieldAll = field === "IsClockingAllowed" ? "clockingAll" : field === "IsFilingAllowed" ? "filingAll" : field === "IsPayrollTransactionAllowed" ? "payrollTransactionAll" : "dtrAll";

        if( value === false ){
            this.setState({[fieldAll]:false});
        }
        else{
            if( this.state.list.some( x => x[field] === !value ) == false ){
                this.setState({[fieldAll]:true});
            }
        }
        this.setState({ list });
        
        // const item = list.find(x => x._id == id);
        
    }

    onToggleAll = (field) => {
        var value = !this.state[field];
        this.setState({[field]:value});
        this.state.list.forEach( x=> {
            x[field === "clockingAll" ? "IsClockingAllowed" : field === "filingAll" ? "IsFilingAllowed" : field === "payrollTransactionAll" ? "IsPayrollTransactionAllowed" : "isDTRAllowed"] = value;
        })
        this.setState({list:this.state.list});
    }

    onSave = () => {
        this.setState({ loading: true })
        ajaxPost({
            url: "api/RemoteClockAssignment/saveMultiple",
            data: {
                "models": this.state.list
            },
            onSuccess: data => {
                if (data == true) {
                    this.setState({ showOkDialog: true, loading: false });
                }
            },
            finally: () => { }
        })
    }

    advanceFilterCallBack = (advanceFilter, includeDeleted) => {
        var hasAdvFilter = true;
        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length == 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length == 0) &&
            (advanceFilter.divisionIds === undefined || advanceFilter.divisionIds === null || advanceFilter.divisionIds.length == 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length == 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length == 0))
            hasAdvFilter = false

        this.setState({
            minIndex: 0,
            maxIndex: 0,
            employeeList: [],
            advanceFilter: advanceFilter,
            hasAdvanceFilter: hasAdvFilter,
            isFilterActive: false,
            includeInactive: includeDeleted,
            empSearchList: []
        }, () => {
            // this.onClickSearch(null); 
            this.loadSearchEmployee(""); this.doSearch("", this.state.activePage);
        })
    }

    render() {
        const { selected } = this.state;
        const { styles } = this;

        return (
            <div className={modStyles.container}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Remote Clock Assignments</h2>
                </div>
                <br />
                <div style={styles.toolbar}>
                    <Menu secondary size='mini' style={{ width: '100%' }}>
                        <Button loading={this.state.loading} size='small' type='submit' style={{ 'max-height': '3em', marginRight: '6px' }} onClick={this.onSave.bind(this)}
                            disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")}>Save</Button>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button size="small" icon="filter" onClick={this.onViewFilterClick.bind(this, true)} style={{ 'max-height': '3em', marginRight: '6px' }} />
                            <div>
                                <Select
                                    placeholder='Search...'
                                    value={selected}
                                    options={empSearchList(this.state.empSearchList)}
                                    onChange={this.onEmployeeSelect.bind(this)}
                                    onInputChange={this.onSearch}
                                    isMulti
                                    styles={customStyles}
                                    isLoading={this.state.isSearchLoading}
                                />
                            </div>
                            <Button size='small' type='submit' style={{ 'max-height': '3em', marginLeft: '6px' }} loading={this.state.loading} onClick={this.onClickSearch.bind(this)}>Search</Button>
                        </div>
                    </Menu>
                </div>
                <br />
                <div>
                    <Table celled style={styles.table}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Employee</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>Clock In/Out</div>
                                    <Radio toggle checked={this.state.clockingAll} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleAll.bind(this, "clockingAll")}></Radio>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>Transaction Filing</div>
                                    <Radio toggle checked={this.state.filingAll} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleAll.bind(this, "filingAll")}></Radio>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>Payroll Transaction</div>
                                    <Radio toggle checked={this.state.payrollTransactionAll} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleAll.bind(this, "payrollTransactionAll")}></Radio>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>DTR</div>
                                    <Radio toggle checked={this.state.dtrAll} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleAll.bind(this, "dtrAll")}></Radio>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.list.map(x => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{x.EmployeeName}</Table.Cell>
                                        <Table.Cell>
                                            <Radio toggle checked={x.IsClockingAllowed} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleChange.bind(this, x.EmployeeId, "IsClockingAllowed")}></Radio>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Radio toggle checked={x.IsFilingAllowed} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleChange.bind(this, x.EmployeeId, "IsFilingAllowed")}></Radio>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Radio toggle checked={x.IsPayrollTransactionAllowed} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleChange.bind(this, x.EmployeeId, "IsPayrollTransactionAllowed")}></Radio>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Radio toggle checked={x.isDTRAllowed} disabled={!isTaskAvailable("REMOTECLOCK_ASSIGNMENT_UPDATE")} onChange={this.onToggleChange.bind(this, x.EmployeeId, "isDTRAllowed")}></Radio>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
                {
                    this.state.showOkDialog &&
                    <MessageBoxOkOnly title="Remote Clock Assignments"
                        action="OK"
                        onClick={() => this.setState({ showOkDialog: false })}
                        caption="Remote Clock Assignments successfully saved"
                    />
                }
                {
                    this.state.isFilterActive &&
                    <EmployeeFilterModal
                        open={true}
                        advFilter={this.state.advanceFilter}
                        onCancel={this.onViewFilterClick.bind(this, false)}
                        callback={this.advanceFilterCallBack.bind(this)}
                        hideFilter={["EMPLOYEE"]}
                    />
                }
            </div >
        )
    }
}

RemoteClockAssignmentList.getViewID = () => { return "RemoteClockAssignmentModule" };
RemoteClockAssignmentList.getCaption = () => { return "Remote Clock Assignment" }
RemoteClockAssignmentList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Clock_Assignment.png' avatar />); }
RemoteClockAssignmentList.getIconSrc = (size) => { return '/Icons/Clock_Assignment.png' }
RemoteClockAssignmentList.getViewAccessKey = () => {
    return isTaskAvailable("REMOTECLOCK_ASSIGNMENT_VIEW");
}

export default RemoteClockAssignmentList