import React, { Component } from 'react';
import Select from 'react-select';
import 'semantic-ui-css/semantic.min.css';
import { Button, Input, Modal } from 'semantic-ui-react';
import { ajaxPost } from '../../../ajax';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import SelectEmployees from '../../../Commons/SelectEmployees';
import { customStyles, empty_id, errorStyles, handleNumericOnKeyDown, handleNumericOnKeyPress, isTaskAvailable, isNullOrEmpty } from '../../../utils';
import CommonTypeForm from '../../Commons/CommonTypeEditForm';



const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }

var delayTimer = null;

const monthList = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
]

const TransactionTypes = [
    { label: "Taxable Income", value: "TAXABLE_INCOME" },
    { label: "Non-Taxable Income", value: "NON_TAXABLE_INCOME" },
    { label: "Taxable Deduction", value: "TAXABLE_DEDUCTION" },
    { label: "Non-Taxable Deduction", value: "NON_TAXABLE_DEDUCTION" },
    { label: "Tax", value: "TAX" },
    // { label: "Employer Contribution", value: "EMPLOYER_CONTRIBUTION" },
    // { label: "Government Contribution", value: "GOVERNMENT_CONTRIBUTION" }
]

const PaymentFrequency = [
    { label: "Every Cutoff", value: "EVERY_CUTOFF" },
    { label: "Last Cutoff/Week", value: "LAST_CUTOFF" },
    { label: "First Cutoff/Week", value: "FIRST_CUTOFF" },
    { label: "Second Cutoff/Week", value: "SECOND_CUTOFF" },
    { label: "Third Week", value: "THIRD_CUTOFF" },
    { label: "Fourth Week", value: "FOURTH_CUTOFF" },
]

function selectionList(empList, fields, value) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: fields.map(z => { return (x[z] + " ") }), value: x[value] }))
        })
    }
    return list;
}

function populateYear() {
    var startYear = 1990;
    var tempMonthList = [];
    for (var i = startYear; i <= 2999; i++) {
        tempMonthList.push({ label: i, value: i });
    }
    return tempMonthList;
}

class PayrollAdjustmentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Model: this.getDefaultValues(),
            selectedPayrollAdustmentType: null,
            lstYearSelection: [],
            showHoursField: false,
            showRequiredField: this.props.showHoursField,
            errCaption: this.props.errCaption,
            isEmployeeError: false,
            isAdjustmentTypeError: false,
            isTransactionTypeError: false,
            isFrequencyError: false,
            showError: false,
        }
        this.setState({ selectedPayrollAdustmentType: null });
        this.modalRef = React.createRef();
        this.parentRef = React.createRef();
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        // if (this.isNullOrEmpty(data.Name)
        // 	|| this.isNullOrEmpty(data.Description)
        // ) {
        // 	errCaption = "Please fill up all required field/s";
        // 	errTitle = "Invalid";
        // }
        if (isNullOrEmpty(data.Name)) {
            errCaption = "'Name' field is required";
            errTitle = "Invalid";
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;

        isValid = this.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true });
        let addNew = isNullOrEmpty(data._id) || data._id == empty_id;

        var parameter = {
            data: {
                model: JSON.stringify(data)
            },
            url: "api/PayrollAdjustmentType/save",
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    self.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    }, () => {
                        self.messageBoxOkOnlyOpen(true, "Failed", "Data not successfully saved");
                    });
                    return;
                }
                isValid = true;

                var model = this.state.Model;
                model.PayrollAdjustmentTypeID = data.content["_id"];
                this.setState({ PayrollAdjustmentTypeID: data.content["_id"], Model: model });
                const obj = { key: data.content["_id"], text: data.content["Name"], label: data.content["Name"], value: data.content["_id"] }

                this.setState({ selectedPayrollAdustmentType: obj });
                this.loadTypes("");
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) {
                    self.setState({ showAdjustmentTypeForm: false, isSaving: false });
                };
            }
        }
        ajaxPost(parameter);
    }

    getDefaultValues = () => {
        return {
            _id: empty_id,
            selectedEmployees: [],
            PayrollAdjustmentTypeID: empty_id,
            TransactionType: "",
            Amount: 0,
            Hours: 0,
            StartMonth: new Date().getMonth() + 1,
            StartYear: new Date().getFullYear(),
            StartCutoff: -1,
            EndMonth: new Date().getMonth() + 1,
            EndYear: new Date().getFullYear(),
            EndCutoff: -1,
            Frequency: "",
            Remarks: "",
            // RecordDate: ""
        }
    }

    setContent = (data) => {
        let onEdit = data !== null && data.EmployeeID.length > 0;
        if (onEdit) {
            data.selectedEmployees = [data.EmployeeID];
        }
        this.setState({
            Model: data === null || data === undefined ? this.getDefaultValues() : data,
            employeeSelectionEnabled: !onEdit,
            displayedName: onEdit ? data.Employee : "",
            lstYearSelection: populateYear(),
            selectedPayrollAdustmentType: null
        }, () => {
            this.loadInitialData(data !== null? data.PayrollAdjustmentTypeID: undefined);
        });
    }

    getContent = () => {
        var model = this.state.Model;
        return {
            _id: model._id,
            employeeIds: model.selectedEmployees,
            PayrollAdjustmentTypeID: model.PayrollAdjustmentTypeID,
            TransactionType: model.TransactionType,
            Amount: model.Amount,
            StartMonth: model.StartMonth,
            StartYear: model.StartYear,
            StartCutoff: model.StartCutoff,
            EndMonth: model.EndMonth,
            EndYear: model.EndYear,
            EndCutoff: model.EndCutoff,
            Frequency: model.Frequency,
            Remarks: model.Remarks,
            Hours: model.Hours,
            // RecordDate: model.RecordDate
        }
    }
    ComputeAmout = (value) => {
        if( this.state.Model.selectedEmployees.length > 1){
            return;
        }

        if (value.target.value == "")
            return;

        var parameter = {
            EmployeeID: this.state.Model.selectedEmployees[0],
            Hours: value.target.value,
            PayrollAdjustmentTypeID: this.state.Model.PayrollAdjustmentTypeID
        };
        var searchParameter = {
            data: parameter,
            url: "api/Cutoff/computeAdjustment",
            onError: (error) => {
                //self.setState({ leaveTypeData: [] });
            },
            onSuccess: (retData, sender) => {
                if (retData.Error != undefined) {
                    this.setState({ showRequiredField: true, errCaption: retData.Error })
                    return;
                }
                var modal = this.state.Model;
                modal.Amount = retData.Content.Amount;
                this.setState({ Model: modal });
            },
            finally: () => {
                this.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);

    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });

        if (name == "Amount") {
            this.setState({ isAmountError: false });
        }
    }

    handleEmpSelectChange = (name, value) => {
        var current = this.state.Model;
        current[name] = value;
        this.setState({ Model: current, isEmployeeError: false });
    }

    handleSelectChange = (name, errorVariable, evt) => {
       var adjutmentType = this.state.selectedPayrollAdustmentType;
       if(adjutmentType != null && adjutmentType.text === "Leave Conversion: Tax Deduction"){
            if(name === "TransactionType"){
                if(evt.value !== "TAXABLE_DEDUCTION"){ 
                    return;
                }
            }
        }
        var current = this.state.Model;
        if (evt !== null) {
            current[name] = evt.value;
        }
        else {
            current[name] = null;
        }
        this.setState({ Model: current });

        if (errorVariable != undefined)
            this.setState({ [errorVariable]: false })
    }

    handlePayrollAdjustmentTypeChange = value => {
        var { Model } = this.state;
        if (value === null)
            Model.PayrollAdjustmentTypeID = empty_id;
        else
            Model.PayrollAdjustmentTypeID = value.value;
        if (value.text != null) {
            var dtrCode = ["HOURSWORK", "LATE", "UNDERTIME", "OT", "ND", "NDOT", "RD", "RDOT", "RDND", "RDNDOT",
                "HD", "HDOT", "HDND", "HDNDOT", "HDRD", "HDRDOT", "HDRDND", "HDRDNDOT",
                "SD", "SDOT", "SDND", "SDNDOT", "SDRD", "SDRDOT", "SDRDND", "SDRDNDOT"];
            var code = value.text.split(" ");
            if (dtrCode.includes(code[0].toUpperCase())) {
                this.setState({ showHoursField: true });
            }else{
                this.setState({ showHoursField: false });
            }
        }
        if(value.text === "Leave Conversion: Tax Deduction"){
            Model.TransactionType = "TAXABLE_DEDUCTION";
        }
        this.setState({ selectedPayrollAdustmentType: value, Model, isAdjustmentTypeError: false })
    }

    loadInitialData(payrollAdjustmentId){
        var self = this;
        this.setState({ isLoading: true });
        this.setState({ isEmployeeError: false, isAdjustmentTypeError: false, isAmountError: false, isTransactionTypeError: false, isFrequencyError: false });
        // if (this.state.selectedPayrollAdustmentType != null) {
        //     var model = this.state.Model;
        //     model.PayrollAdjustmentTypeID = this.state.selectedPayrollAdustmentType.value;
        //     this.setState({ Model: model });
        // }

            var parameter = {
                queryString: payrollAdjustmentId === undefined? "": payrollAdjustmentId,
                includeDeleted: false,
                startIndex: 0,
                itemCount: -1
            };
            var searchParameter = {
                data: parameter,
                url: "api/PayrollAdjustmentType/search",
                onError: (error) => {
                    //self.setState({ leaveTypeData: [] });
                },
                onSuccess: (retData, sender) => {
                    if (retData.content.length === 0) {
                        let current = self.state.Model;
                        self.setState({ Model: current });
                        return;
                    }
                    var newOptions = retData.content.map((item) => {
                        return {
                            label: item.Name,
                            key: item._id,
                            text: item.Name,
                            value: item._id,
                        }
                    });

                    if(payrollAdjustmentId !== undefined){
                        var selectedPayrollAdustmentType = newOptions.find(x => x.value === payrollAdjustmentId);
                        this.setState({selectedPayrollAdustmentType}, () => this.loadInitialData())
                    }
                    else {
                        self.setState({ payrollAdjustmentTypeList: newOptions});
                    }
                    
                },
                finally: () => {
                    self.setState({ isLoading: false });
                }
            };
            ajaxPost(searchParameter);

    }

    onPayrollAdjustmentTypeKeyPress = (text) => {
        this.setState({ isLoading: true });
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            this.loadTypes(text);
        }, 400);
    }

    loadTypes = (text) => {
        var self = this;
        var parameter = {
            queryString: text,
            includeDeleted: false,
            startIndex: 0,
            itemCount: -1
        };
        var searchParameter = {
            data: parameter,
            url: "api/PayrollAdjustmentType/search",
            onError: (error) => {
                //self.setState({ leaveTypeData: [] });
            },
            onSuccess: (data, sender) => {
                if (data.content.length === 0) {
                    let current = self.state.Model;
                    current["PayrollAdjustmentTypeID"] = "";
                    self.setState({ Model: current });
                    return;
                }
                var newOptions = data.content.map((item) => {
                    return {
                        label: item.Name,
                        key: item._id,
                        text: item.Name,
                        value: item._id,
                    }
                });

                self.setState({ payrollAdjustmentTypeList: newOptions });
            },
            finally: () => {
                self.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);
    }

    onClickSave = () => {
        const { Model } = this.state;
        var isError = false;
        if (Model.selectedEmployees === undefined || Model.selectedEmployees === null || Model.selectedEmployees.length === 0) {
            isError = true;
            this.setState({ isEmployeeError: true });
        }
        if (Model.PayrollAdjustmentTypeID === undefined || Model.PayrollAdjustmentTypeID === empty_id) {
            isError = true;
            this.setState({ isAdjustmentTypeError: true });
        }
        if (Model.TransactionType === undefined || Model.TransactionType === null || Model.TransactionType === "") {
            isError = true;
            this.setState({ isTransactionTypeError: true });
        }
        if (Model.Frequency === undefined || Model.Frequency === null || Model.Frequency === "") {
            isError = true;
            this.setState({ isFrequencyError: true });
        }

        if (Model.Amount === "" || Model.Amount <= 0) {
            isError = true;
            this.setState({ isAmountError: true });
        }

        if (isError) {
            this.setState({ showRequiredField: true, errCaption: "Please fill up all field/s", errTitle: "Invalid" });
            return;
        }


        this.props.onSave();
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    onCreateNewTypeClick = () => {
        this.setState({ showAdjustmentTypeForm: true });
    }

    render() {
        const self = this;
        let model = this.state.Model;
        return (
            <>
                {this.props.open && <Modal size="tiny" open={this.props.open}>
                    <Modal.Header>
                        {this.state.Model._id === "" || this.state.Model._id === empty_id ? "Create" : "Edit"} Payroll Adjustment
                </Modal.Header>
                    <Modal.Content>
                        {
                            this.state.showRequiredField &&
                            <div className="ui one column grid" style={noMarginNoPadding}>
                                <div className="column" style={someMarginNoPadding}>
                                    <ErrorMessage
                                        title={this.state.errTitle}
                                        caption={this.state.errCaption}
                                        open={this.state.showRequiredField}
                                    />
                                </div>
                            </div>
                        }
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            {
                                <div className="column" style={someMarginNoPadding}>
                                    <div>Employees</div>
                                    <SelectEmployees
                                        isMulti={true}
                                        is
                                        Ids={model.selectedEmployees}
                                        update={(Ids) => { this.handleEmpSelectChange("selectedEmployees", Ids); }}
                                        width="100%"
                                        disabled={this.props.isBusy || !this.state.employeeSelectionEnabled}
                                        errorStyle={this.state.isEmployeeError}
                                    />
                                    {
                                        isTaskAvailable("EMPLOYEE_ADD") &&
                                        <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                    }
                                </div>
                                // <div className="column" style={someMarginNoPadding}>
                                //         <div>Employee</div>
                                //         <Header size='small' style={{"margin" : "0"}}>{this.props.ref !== undefined? this.props.Model.EmployeeName: ""}</Header>
                                // </div>
                            }
                        </div>
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Type</div>
                                <Select
                                    value={this.state.selectedPayrollAdustmentType}
                                    options={this.state.payrollAdjustmentTypeList}
                                    onChange={this.handlePayrollAdjustmentTypeChange.bind(this)}
                                    onInputChange={this.onPayrollAdjustmentTypeKeyPress.bind(this)}
                                    styles={this.state.isAdjustmentTypeError ? errorStyles : customStyles}
                                />
                                {
                                    isTaskAvailable("PAYROLL_ADJUSTMENT_TYPE_CREATE") &&
                                    <a as='a' href='#' onClick={this.onCreateNewTypeClick.bind(this)}>Create New</a>
                                }
                                {/* <Dropdown 
                                fluid 
                                search 
                                selection 
                                // disabled={this.props.isBusy} 
                                options={this.state.payrollAdjustmentTypeList} 
                                value={model.PayrollAdjustmentTypeID} 
                                // error={!this.state.PayrollAdjustmentTypeID && this.props.showRequiredField}                                
                                // loading={this.state.isLoading}
                                onClick={this.onPayrollAdjustmentTypeKeyPress} 
                                onSearchChange={this.onPayrollAdjustmentTypeKeyPress}
                                onChange={this.handleChange("PayrollAdjustmentTypeID").bind(this)}
                            /> */}
                            </div>
                        </div>
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Transaction Type</div>
                                <Select
                                    value={TransactionTypes.find(x => x.value === model.TransactionType)}
                                    options={TransactionTypes}
                                    onChange={this.handleSelectChange.bind(this, "TransactionType", "isTransactionTypeError")}
                                    styles={this.state.isTransactionTypeError ? errorStyles : customStyles}
                                />
                            </div>
                        </div>
                        <div className="ui two column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>From</div>
                                <Select
                                    placeholder="Month"
                                    value={selectionList(monthList, ["label"], "value").find(x => x.value === model.StartMonth)}
                                    options={selectionList(monthList, ["label"], "value")}
                                    onChange={this.handleSelectChange.bind(this, "StartMonth", "")}
                                />
                            </div>
                            <div className="column" style={someMarginNoPadding}>
                                <div>&nbsp;</div>
                                <Select
                                    placeholder="Year"
                                    value={this.state.lstYearSelection.find(x => x.value === model.StartYear)}
                                    // options={populateYear()}
                                    options={this.state.lstYearSelection}
                                    onChange={this.handleSelectChange.bind(this, "StartYear", "")}
                                />
                            </div>
                        </div>
                        <div className="ui two column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>To</div>
                                <Select
                                    placeholder="Month"
                                    value={selectionList(monthList, ["label"], "value").find(x => x.value === model.EndMonth)}
                                    options={selectionList(monthList, ["label"], "value")}
                                    onChange={this.handleSelectChange.bind(this, "EndMonth", "")}
                                />
                            </div>
                            <div className="column" style={someMarginNoPadding}>
                                <div>&nbsp;</div>
                                <Select
                                    placeholder="Year"
                                    value={this.state.lstYearSelection.find(x => x.value === model.EndYear)}
                                    // options={populateYear()}
                                    options={this.state.lstYearSelection}
                                    onChange={this.handleSelectChange.bind(this, "EndYear", "")}
                                />
                            </div>
                        </div>
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Frequency</div>
                                <Select
                                    placeholder="Payment Frequency"
                                    value={selectionList(PaymentFrequency, ["label"], "value").find(x => x.value === model.Frequency)}
                                    options={selectionList(PaymentFrequency, ["label"], "value")}
                                    onChange={this.handleSelectChange.bind(this, "Frequency", "isFrequencyError")}
                                    styles={this.state.isFrequencyError ? errorStyles : customStyles}
                                />
                            </div>
                        </div>
                        {this.state.showHoursField &&
                            <div className="ui one column grid" style={noMarginNoPadding}>
                                <div className="column" style={someMarginNoPadding}>
                                    <div>Hours</div>
                                    <Input
                                        placeholder='Hours'
                                        value={model.Hours}
                                        style={{ 'width': '250px' }}
                                        error={this.state.isAmountError}
                                        onChange={this.handleChange("Hours").bind(this)}
                                        onBlur={this.ComputeAmout.bind(this)}
                                        disabled={model.selectedEmployees.length == 0}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>
                        }
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Amount</div>
                                <Input
                                    placeholder='Amount'
                                    value={model.Amount}
                                    style={{ 'width': '250px' }}
                                    error={this.state.isAmountError}
                                    onChange={this.handleChange("Amount").bind(this)}
                                    onFocus={() => { this.setState({ isAmountFocus: true }) }}
                                    onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                    onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                />
                            </div>
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon='save' content="Submit" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.onClickSave.bind(this)}></Button>
                        <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                    </Modal.Actions>
                    {
                        this.state.showAskCloseDialog &&
                        <MessageBoxYesNo title="Create New Employee"
                            action="Yes"
                            onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                            onAction={() => { this.props.onCreateEmpClick() }}
                            caption="All unsaved changes will be lost. Do you wish to continue?"
                        />
                    }
                    {
                        <CommonTypeForm Title="Create New Type" open={this.state.showAdjustmentTypeForm}
                            ref={self.modalRef}
                            errTitle={self.state.errTitle}
                            errCaption={self.state.errCaption}
                            showRequiredField={self.state.showRequiredField}
                            isBusy={self.state.isSaving}
                            onCancel={() => { 
                                self.setState({ showAdjustmentTypeForm: false }, self.setState({ showRequiredField: false })); 
                            }}
                            onSave={() => {
                                self.saveFromModal(
                                    self.modalRef.current.getContent()
                                );
                            }} />
                    }
                </Modal>}
            </>
        );
    }
}
export default PayrollAdjustmentForm